// 本棚査定用のJavaScript
// 今のところsp版のみ対応
// ES2017で定義された async/await を使っているため、
// IE11に対応が必要な場合は、Babelなどでトランスコンパイルする必要がある

import Amplify, {API, Storage} from 'aws-amplify';
import awsconfig from './aws-exports';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';
import imageCompression from "browser-image-compression";


import UUIDv4 from "uuid/v4";
const uuidv4 = UUIDv4;
import Path from 'path';
const path = Path;


const MAX_WIDTH_OR_HEIGHT = 1024; // S3へアップロードする前にリサイズする時の、最大画像幅もしくは高さ
const MAX_FILE_SIZE = 10 * 1024 * 1024;  // S3へアップロード可能な画像の最大サイズ

const FIRST_SUBSCRIPTION_TIMEOUT = 10000;  // 1段目Subscriptionのタイムアウトミリ秒
const SECOND_SUBSCRIPTION_TIMEOUT = 20000;  // 2段目Subscriptionのタイムアウトミリ秒
const FIRST_SUBSCRIPTION_TIMEOUT_DELAY = 1000;  // 1段目と、1段目直後の2段目でタイムアウトを同じ値にしないようにするための遅延ミリ秒

const DEFAULT_SEPARATION_COUNT = 50;  // 期待される画像分割サイズ(この値を元に、1段目直後の2段目Subscriptionを実行)

const SUBSCRIPTION_ERROR_CODE_NOT_FOUND = 'E1';  // Subscriptionで1件もデータがない時のエラーコード
const SUBSCRIPTION_ERROR_CODE_ERROR = 'E2';      // Subscriptionでエラーが発生した時のエラーコード

// S3へアップロードしたファイルをセッションストレージに置く際のキー
const SESSION_STORAGE_KEY_OF_UPLOAD_FILE = 'bookshelf-assess-uploaded-file';

const NO_RESULT_LOAD_TIMEOUT = 60000; // 結果なしで正常終了時の場合画面側をタイムアウトで終了させるタイムアウトミリ秒

const ID_SELECTOR_NAMES = [
    '#take-picture-of-bookshelf-button',
    '#take-picture-of-bookshelf-button_under',
    '#take-picture-of-bookshelf-button_float'
];


// ----------------------
// ヘッダでの処理
// ----------------------

/**
 * イベント設定
 */
$(function() {
    // 本棚査定用写真を選択した時の処理
    $(ID_SELECTOR_NAMES.join()).change(function() {
        console.log("test ID_SELECTOR_NAMES")
        const file = $(this).prop('files')[0];
        if(file == null) {
            return handleError('#modal-bookshelf-assessment-upload-failed');
        }

        if (file.size > MAX_FILE_SIZE) {
            return handleError('#modal-bookshelf-assessment-image-too-big');
        }

        assessBookshelfWithResize(file);
    });
    // 指定リンクから本棚スキャンを実行する
    $(document).on('click', '.scan-click', function(){
        amplifyConfigure();
        const clickImageFileId = $(this).data('scan-file-id');
        const clickShelfType = $(this).data('shelf-type');
        let clickImageFilePath = $('#bookshelf-image-' + clickShelfType).prop('currentSrc');
        // 結果画面内のその他の本棚がクリックされた場合
        if(clickImageFilePath === undefined) {
            clickImageFilePath = $(this).find('img').attr('src');
        }

        specifyBookShelfScan(clickImageFileId, clickImageFilePath, clickShelfType);
    });
});

/**
 * 指定本棚スキャン用のセッションストレージ格納と遷移処理
 */
async function specifyBookShelfScan(clickImageFileId, clickImageFilePath, clickShelfType) {
    // セッションストレージへ保存するためにサーバに配置された画像をFileオブジェクトに変換
    // ストレージに格納が終わる前にPOSTしてしまうと、正常に格納されないため処理を待つ
    await createFileObject(clickImageFilePath).then((item) => {
        var fileReader  = new FileReader();
        fileReader.onload  = function (evt) {
            var base64FileData = evt.target.result;
            // セッションストレージへ保存
            sessionStorage.setItem(SESSION_STORAGE_KEY_OF_UPLOAD_FILE, base64FileData);
        };
        fileReader.readAsDataURL(item);
    });
    // おためし査定画面へPOST
    submitBookshelfAssessmentForm(clickImageFileId, clickShelfType);
}
/**
 * 圧縮した本棚画像をS3に保存し、おためし査定画面へ遷移
 * @param file 圧縮前のファイル
 * @returns {Promise<void>}
 */
async function assessBookshelfWithResize(file) {
    // 使い方ポップアップ画面内の説明要素を非表示
    $("#barcode-start-box-inner").css("display","none");
    // 見出しを書き換える
    $(".barcode-start-modal .title em").text('スキャンしています...');
    // ポップアップ画面内でプログレスバーを表示
    $("#progressFileUploadDiv").css("display","");

    const options = {
        maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT // 最大画像幅もしくは高さ
    };
    try {
        // ぐるぐる表示開始
        searcingPopup.popup('show');
        // 圧縮画像の生成
        const compressedImage = await imageCompression(file, options);
        // 圧縮画像を使ってアップロード
        assessBookshelf(compressedImage);

    } catch (error) {
        return handleError('#modal-bookshelf-assessment-upload-failed');
    }
}


/**
 * エラー処理を行う
 * @param popupID 表示するポップアップのHTML ID
 * @returns {boolean}
 */
function handleError(popupID) {
    // 同じファイルを再度選択できるよう、いったん選択したファイルはクリア
    $(ID_SELECTOR_NAMES.join()).val(null);
    $('#barcode-start').popup('hide'); // 使い方ウィンドウがオープンされていれば閉じる
    showPopup(popupID);
    return false
}


/**
 * ポップアップを表示する
 * @param popupID 表示するポップアップのHTML ID
 */
function showPopup(popupID) {
    const element = $(popupID);
    element.popup({
        transition : 'all 0.3s'
    });
    element.find('.modal-close-btn').click(function() {
        element.popup('hide');
    });
    element.popup('show');
}


/**
 * 本棚画像をS3に保存し、おためし査定画面へ遷移
 * @param imageFile 本棚画像
 * @returns {Promise<void>}
 */
async function assessBookshelf(imageFile) {
    amplifyConfigure();

    const uploadFile = createUploadFilePath(imageFile);
    const fullPath = `${uploadFile.dir}/${uploadFile.fileName}`;
    const uploaded = await uploadStorage(fullPath, imageFile);

    var fileReader  = new FileReader();
    fileReader.onload  = function (evt) {
        var base64FileData = evt.target.result;
        // 次の画面で表示するために、セッションストレージを使う
        // セッションストレージのキーは、今のところ同一にする
        // (履歴表示が必要になったら、セッションストレージではあふれるおそれもあるため、その時は技術要素から検討)
        sessionStorage.setItem(SESSION_STORAGE_KEY_OF_UPLOAD_FILE, base64FileData);
    };
    fileReader.readAsDataURL(imageFile);

    // ぐるぐる表示終了
    searcingPopup.popup('hide');

    // おためし査定画面へPOST
    submitBookshelfAssessmentForm(uploadFile.fileName);
}


/**
 * S3へアップロードするファイル情報を作成する
 * ユーザーがアップロードした時のファイル名は使用しない
 * なお、DynamoDBのfileには、"uuidv4_<タイムスタンプ>.<拡張子>" という形式でキー(file)が設定される
 *
 * @param imageFile S3へアップロードするファイル
 * @returns {*} S3へアップロードするファイル情報
 */
function createUploadFilePath(imageFile) {
    const currentUUID = uuidv4();
    const dateTimeNow = formatDateTimeNow();
    const extname = path.extname(imageFile.name);

    return {
        dir: currentUUID,  // ディレクトリ名
        fileName: `${currentUUID}_${dateTimeNow}${extname}`  // ファイル名
    };
}


/**
 * ストレージへのアップロード
 * @param uploadFileName アップロードするファイルのフルパス
 * @param imageFile 本棚画像
 * @returns {Promise<Object>}
 */
function uploadStorage(uploadFileName, imageFile) {
    return Storage.put(uploadFileName, imageFile, {
        progressCallback(progress) {
            const progressPercent = Math.floor(parseInt(progress.loaded, 10) / parseInt(progress.total, 10) * 100);
            // 画像アップロードプログレスバーを進捗に応じて幅を加算
            $('#progress-bar').css('width', progressPercent + '%');
            // GTMにアップロード判定用のレイヤーをプッシュ
            if (progressPercent === 100) {
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({'gtmScanFileUpload': 'true'});
            }
        }
    })
}


/**
 * アップロードファイル名を使って、おためし査定画面へPOST
 * @param uploadFileName アップロードファイル名
 */
function submitBookshelfAssessmentForm(uploadFileName, type = false) {
    const form = $('#form_estimate_search');
    $('<input/>', {
        type : "hidden",
        name : "fileName",
        value : uploadFileName,
    }).appendTo(form);
    // 指定本棚スキャンの場合、本棚属性をクエリに含めてURLにクエリパラメータを付与する
    if (type !== false) {
        $('<input/>', {
            type : "hidden",
            name : "shelfType",
            value : type,
        }).appendTo(form);
        form.attr('action', '/estimate/search?type=' + type);
    }
    form.submit();
}


/**
 * Amplifyの設定
 * 今後、aws-exports.jsを使わないかもしれないため、ひとまず関数化した
 */
function amplifyConfigure() {
    Amplify.configure(awsconfig);
}


/**
 * 現在日時の文字列を取得する
 * @returns {*} 現在日時の文字列
 */
function formatDateTimeNow() {
    const dt = new Date();
    return (
        dt.getFullYear() +
        ("00" + (dt.getMonth()+1)).slice(-2) +
        ("00" + dt.getDate()).slice(-2) +
        ("00" + dt.getHours()).slice(-2) +
        ("00" + dt.getMinutes()).slice(-2) +
        ("00" + dt.getSeconds()).slice(-2)
    )
}


/**
 * デバイスの向き切替時を考慮
 */
$(window).on("orientationchange", function() {
    const Bookshelfcontainer = new BookshelfAssessmentContainer();
    // 向き切り替え時に再度高さを取得し直す
    Bookshelfcontainer.getUploadImgHeight();
});
/**
 * サーバに配置された指定画像をFileオブジェクトに変換
 * @param inputImage imgタグのsrc
 */
function createFileObject(inputImage){

    return axios.get(inputImage, {
                responseType: "arraybuffer"
            }).then(response => {
                const options = {
                    maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT // 最大画像幅もしくは高さ
                };
                var arrayBuffer = response.data;
                // Fileオブジェクトを生成する
                var file = new File([arrayBuffer],"", {type: "image/jpeg"});
                // 圧縮画像の生成
                const compressedImage = imageCompression(file, options);
                return compressedImage;
            }).catch(err => {
                console.log('ImageFile get error');
                return false;
            });
}
// ----------------------
// おためし査定画面での処理
// ----------------------

/**
 * おためし査定画面に、本棚査定結果を表示するためのクラス
 * 処理のメイン部分、BladeのDOMを操作する部分、外部JSに依存する部分にメソッドを分けた
 */
class BookshelfAssessmentContainer {
    /**
     * コンストラクタ
     * @param element おためし査定画面に、本棚査定結果を表示するためのelement
     * @param pricingUrl 価格APIのURL
     * @param openBdUrl OpenBD APIのURL
     * @param uploadFileName S3へアップロードしたファイル名(UUID v4形式を想定)
     */
    constructor(element, pricingUrl, openBdUrl, uploadFileName) {
        this.element = element;
        this.pricingUrl = pricingUrl;
        this.openBdUrl = openBdUrl;
        this.uploadFileName = uploadFileName;

        // Subscriptionを受け取ってDOM表示したASINを入れておくためのSet
        // 同一のASINを持つ本を複数回表示させないために使用
        this.subscribedAsinList = new Set();

        // 2段階目のSubscribe中のファイル名を管理しておくためのMap
        // キー無し -> 未Subscribe
        // キー有、値：None -> Subscription受信済
        // キー有、値：[clientオブジェクト, timeoutオブジェクト]という配列 -> Subscribe中
        this.subscribingFiles = new Map();
    }

    /**
     * 本棚査定結果を表示するメソッド
     * インスタンス化後は、このメソッドを呼ぶだけで良いと想定
     */
    async render() {
        this.showLoadingContent();
        this.amplifyConfigure();

        const response = await this.fetchDynamoDB(this.uploadFileName);

        if (response.data.getBookshelfAssessment == null) {

            // 1段目のSubscribeを実施
            this.subscribe(this.uploadFileName, this.subscribeImages, FIRST_SUBSCRIPTION_TIMEOUT);

            // パフォーマンス改善のため、1段目のSubscribeが完了していない状態でも、2段目もSubscribe
            for (let i = 0; i < DEFAULT_SEPARATION_COUNT; i++) {
                const key = this.formatSubscriptionKey(this.uploadFileName, i);

                // 1段目と、1段目直後の2段目のタイムアウトミリ秒が同じだと、1段目がDyanmoDBを見に行っている間に、
                // 本来不要な2段目がタイムアウトを迎え、プログレスバーの描画をしてしまう。
                // JavaScriptの秒計測の仕様上仕方ないことなので、1段目と2段目では同じ秒数を入れないようにしている
                // 秒単位で遅延させれば、DynamoDBを見に行っていたとしても、1秒以内には返信があるため、問題ないと考えられる
                this.subscribe(key, this.renderItems, SECOND_SUBSCRIPTION_TIMEOUT + FIRST_SUBSCRIPTION_TIMEOUT_DELAY);
            }
        }
        else {

            // 1段目のDynamoDB更新後なので、現在のデータを描画しつつ、更新が続くかもしれないため、subscribeする
            // (このケースはリロードやブラウザバック、超低速回線を想定)
            // なお、1段目のSubscribeについては、空更新は行われない前提
            const resultList = this.getResultList(response.data.getBookshelfAssessment.result);
            this.redrawItems(resultList);
        }
    }

    /**
     * subscriptionで受信したimagesごとに、再度subscribeする
     * なお、コールバックとして呼ばれるため、thisをインスタンスにバインドできるアロー形式にて定義
     * @param uploadFileName
     * @param resultList Subscriptionで受け取った、resultの中にあるresult_list
     */
    subscribeImages = (uploadFileName, resultList) => {
        // おためし査定画面に、分割数をセット
        const total = resultList[0].images.length;
        this.setTotalOfSeparations(total);

        // 分割数 < Subscribe済数の場合、不要なSubscribeを終了し、タイムアウトも解除する
        if (total < DEFAULT_SEPARATION_COUNT) {
            this.unsubscribeDisuseSubscriptions(uploadFileName, DEFAULT_SEPARATION_COUNT - total);
        }

        // 分割した画像分、subscriptionを発行
        for (const result of resultList) {
            for (const image of result.images) {
                this.subscribe(image, this.renderItems, SECOND_SUBSCRIPTION_TIMEOUT)
            }
        }
    };

    /**
     * 解析したASINとタイトル名のアイテムのうち、まだDOMに存在しないものを表示する
     * * なお、コールバックとして呼ばれることもあるため、thisをインスタンスにバインドできるアロー形式にて定義
     * @param targetFileName S3にアップロードしたときのファイル名
     * @param resultList 解析したASINとタイトル名を要素として持つ配列
     */
    renderItems = (targetFileName, resultList) => {
        const targetItems = [];
        for (const result of resultList) {
            // DOM表示する前に、他のSubscriptionで同一ASINをDOM表示しないように、subscribedAsinListを更新しておく
            if (!this.subscribedAsinList.has(result.asin)){
                this.subscribedAsinList.add(result.asin);

                // おためし査定のBladeでファイル名の昇順に並べるため、ファイル名にあるspineの値を付与しておく
                result.spine = this.getSpineNoFromFileName(targetFileName);
                targetItems.push(result);

            }
            else {
                // ASINが重複する場合、進捗バーまわりの処理だけ実施
                console.log(`%c【同じASINがありました】 ${result.asin}`, 'color:red');

                this.drawProgressBarAtNoResultByBooksearch();
            }
        }

        if (targetItems.length > 0) {
            // ぐるぐる表示終了
            this.hideProgressPopup();

            // おためし査定のBladeにある関数で描画する
            this.renderItemsByBlade(targetItems);
        }
    };

    /**
     * 指定したファイル名に対してSubscriptionを実行し、受信後にコールバックを実行するメソッド
     * @param targetFileName Subscriptionのキーとして使うファイル名
     * @param callback 受信後に行う処理が実装されたコールバック
     * @param timeoutSec Subscriptionをタイムアウトするミリ秒
     * @returns {Promise<void>}
     */
    async subscribe(targetFileName, callback, timeoutSec) {

        // すでにsubscribeしているファイルの場合は、重複したsubscribeは不要
        if (this.subscribingFiles.has(targetFileName)) {
            return;
        }

        const client = API.graphql(
            {
                query: subscriptions.onCreateBookshelfAssessment,
                authMode: 'AWS_IAM',
                variables: {
                    // キーは、S3にあるディレクトリ名無しのファイル名
                    file: targetFileName
                }
            }
        ).subscribe({
            next: (response) => {
                // subscriptionの受信は1回という前提のため、受信したらunsubscribe() & タイムアウトして問題ない
                const done = this.unsubscribe(targetFileName);
                if (!done) {
                    // 何らかの原因ですでにunsubscribeされていた場合は、次の処理は実施済と考えて、何もしない
                    return;
                }
                this.runNext(targetFileName, client, response.value.data.onCreateBookshelfAssessment, subscriptionTimeout, callback);
            },
            error: (err) => {

                this.unsubscribe(targetFileName);

                return this.showSubscriptionErrorPopup();
            },
            close: () => {
                // closeの場合は、Subscriptionが終了していると考えられるため、unsubscribe()しない
                this.subscribingFiles.set(targetFileName, null);
                return this.showSubscriptionErrorPopup();
            }
        });

        // 上で定義してあるsubscribe().next()の中でsubscriptionTimeoutを解除するため、ブロックスコープを作らないvarで定義
        var subscriptionTimeout = setTimeout(async () => {

            const done = this.unsubscribe(targetFileName);
            if (!done) {
                // 何らかの原因ですでにunsubscribeされていた場合は、次の処理は実施済と考えて、何もしない
                return;
            }

            // Subscribe始める前にMutationが入れ違いで実行された可能性もあるため、DynamoDBを見る
            const response = await this.fetchDynamoDB(targetFileName);

            // 入れ違いで実行された場合は、DynamoDBにあるMutation内容を元に、subscribe().next()と同じ処理を実行する
            if (response.data.getBookshelfAssessment != null) {
                return this.runNext(targetFileName, client, response.data.getBookshelfAssessment, subscriptionTimeout, callback);
            }

            // 1段目がタイムアウトした場合は、エラーを表示する
            if (!this.isFinishedSubscriptionOfImageSeparation()) {
                // 2段目のSubscriptionをすべて止める
                this.unsubscribeDisuseSubscriptions(targetFileName, DEFAULT_SEPARATION_COUNT);
                // ぐるぐる表示終了
                this.hideProgressPopup();
                return this.showErrorPopup('#modal-bookshelf-assessment-timeout');
            }

            // 入れ違いではなく本当のタイムアウトの場合は、プログレスバーまわりの処理を実施
            this.drawProgressBarAtNoResultByBooksearch();

            // すべてのSubscriptionが終了し、1件も表示されていなければ、すべてがタイムアウトになっているため、エラーを表示する
            if (this.isFinishedSubscriptions() && this.countAssessedItems() === 0) {
                // ぐるぐる表示終了
                this.hideProgressPopup();
                return this.showErrorPopup('#modal-bookshelf-assessment-timeout');
            }
        }, timeoutSec);

        // subscribeを開始したため、subscribe中情報を保持
        this.subscribingFiles.set(targetFileName, [client, subscriptionTimeout]);

        const responseDynamoDB = await this.fetchDynamoDB(targetFileName);
        if (responseDynamoDB.data.getBookshelfAssessment != null) {
            // Subscription実行後、何らかの原因でDynamoDBがMutationされた場合には、Subscriptionを受け取れない
            // この場合は、DynamoDBにあるMutation内容を元に、subscribe().next()と同じ処理を実行する
            // おもに、スマホの回線速度が低速の場合を想定
            const done = this.unsubscribe(targetFileName);
            if (!done) {
                // 何らかの原因ですでにunsubscribeされていた場合は、次の処理は実施済と考えて、何もしない
                return;
            }
            this.runNext(targetFileName, client, responseDynamoDB.data.getBookshelfAssessment, subscriptionTimeout, callback);
        }
    }

    /**
     * Subscription受信後に、コールバックの準備・実行を行う
     * @param targetFileName Subscriptionを受け取った時のファイル名
     * @param client Subscriptionを実行した時のクライアント
     * @param response Subscriptionの内容(DynamoDBのデータが設定されることを想定)
     * @param subscriptionTimeout Subscriptionのタイムアウト
     * @param callback コールバック
     */
    runNext(targetFileName, client, response, subscriptionTimeout, callback) {
        // Mutationのパターンとしては3パターンあるので、それぞれ処理をする
        // 成功
        // エラー
        // 結果がない
        const errorCode = response.error;
        if (errorCode != null){
            // エラーパターン
            this.unsubscribe(targetFileName);
            return this.showBackendErrorPopup(errorCode);
        }

        if(response.result) {
            // 成功パターン
            // 受信した時のresultListの中身を使ってコールバックを実行するため、中身を取得しておく
            const resultList = this.getResultList(response.result);
            callback(targetFileName, resultList);
        } else {
            // 結果がないパターン
            // ここは、2段目でOCR結果がない場合を想定
            // 結果がない場合、表示するものもないため、プログレスバーまわりの処理を実施
            this.drawProgressBarAtNoResultByBooksearch();
        }
    }

    /**
     * 本棚査定結果を再描画する
     * なお、1段階目のSubscriptionは受信できている、という前提
     * @param resultList 1段階目のSubscriptionで受信した result_list
     */
    async redrawItems(resultList) {
        // おためし査定画面に、総分割数をセット
        this.setTotalOfSeparations(resultList[0].images.length);

        for (const result of resultList) {
            for (const image of result.images) {
                const response = await this.fetchDynamoDB(image);
                if (response.data.getBookshelfAssessment == null) {
                    // imagesのファイル名でデータがない場合は、2段目のSubscribe中に再描画が必要になったと考えて、再度Subscribe
                    this.subscribe(image, this.renderItems, SECOND_SUBSCRIPTION_TIMEOUT)
                } else {
                    // imagesのファイル名でデータがあった場合は、2段目のSubscribeが終わったと考えて、そのまま描画
                    if(response.data.getBookshelfAssessment.result) {
                        const resultListForRedraw = this.getResultList(response.data.getBookshelfAssessment.result);
                        this.renderItems(image, resultListForRedraw);
                    } else {
                        // 空データで更新された時は、OCR解析ができないと考えて、プログレスバーまわりの処理を実施
                        this.drawProgressBarAtNoResultByBooksearch();
                    }
                }
            }
        }
    }

    /**
     * アップロードしたファイルに対して一括で、不要なSubscribeを終了しタイムアウトを削除する
     * @param uploadFileName アップロードしたファイル名
     * @param disuseCount 不要なSubscription数
     */
    unsubscribeDisuseSubscriptions(uploadFileName, disuseCount) {
        // 不要なSubscription数だけ、分割数(spine0xx)の大きなSubscriptionから止めていく
        for (let i = DEFAULT_SEPARATION_COUNT; DEFAULT_SEPARATION_COUNT - disuseCount <= i; i--) {
            // spineは0始まりなことに注意
            const key = this.formatSubscriptionKey(uploadFileName, i);
            this.unsubscribe(key);
        }
    }

    /**
     * Subscribeを停止し、タイムアウトも削除
     * タイミングによっては、すでに停止している可能性もあるため、その場合は処理しない
     * @param targetFileName
     * @returns {boolean} true:Subscribeを停止、false:すでに停止されていた
     */
    unsubscribe(targetFileName) {
        const subscribing = this.subscribingFiles.get(targetFileName);
        if (subscribing != null) {

            subscribing[0].unsubscribe();
            clearTimeout(subscribing[1]);
            this.subscribingFiles.set(targetFileName, null);

            return true;
        }

        // すでにunsubscribe済の場合
        return false;
    }

    // ユーティリティメソッド群

    /**
     * 指定したファイル名をキーに、DynamoDBからデータを習得する
     * @param fileName 検索対象キーとなるファイル名
     * @returns {Promise<GraphQLResult> | <object>}
     */
    fetchDynamoDB(fileName) {
        return API.graphql({
            query: queries.getBookshelfAssessment,
            authMode: 'AWS_IAM',
            variables: {
                file: fileName
            }
        })
    }

    /**
     * DynamoDBの項目resultをパースし、result_list を習得する
     * @param result DynamoDBの項目 result
     * @returns {*}
     */
    getResultList(result) {
        const parsedResult = JSON.parse(result);
        return parsedResult.result_list;
    }

    /**
     * ファイル名から、分割番号(spine)を取得する
     * @param fileName ファイル名
     * @returns {number} 分割番号
     */
    getSpineNoFromFileName(fileName) {
        // spineの前を外す
        const spine = fileName.split("_spine");
        // 拡張子以降を外す
        const result = spine.slice(-1)[0].split(".");
        return parseInt(result[0], 10);
    }

    /**
     * ファイル名と分割番号から、2段目のSubscriptionキーを作成する
     * キーの書式：<ファイル名>_spine<分割番号>.<拡張子>
     * @param fileName 対象のファイル名
     * @param spineNo 分割番号(int)
     */
    formatSubscriptionKey(fileName, spineNo) {
        // 分割番号をフォーマット(5桁の数字、0埋めあり)
        const formattedSpineNo = spineNo.toString().padStart(5, '0');

        const fileParts = fileName.split('.');
        const extension = fileParts.pop();
        return `${fileParts.join()}_spine${formattedSpineNo}.${extension}`
    }

    // DOM操作のメソッド群

    /**
     * 本棚査定が終わり、DOMに表示されている冊数を取得する
     * @returns {number|jQuery}
     */
    countAssessedItems() {
        return $('.items > .item').length;
    }

    /**
     * すべてのSubscriptionが終了しているかを取得する
     * @returns {boolean} 終了している場合はtrue
     */
    isFinishedSubscriptions() {
        const progressBar = $('#bookshelf-assessment-progress-bar');
        const done = parseInt(progressBar.attr('data-assess-done'), 10);
        const na = parseInt(progressBar.attr('data-assess-na'), 10);
        const total = parseInt(progressBar.attr('data-assess-total'), 10);
        return (done + na) === total;
    }


    /**
     * 画像分割結果のSubscription(1段目)が終了しているかを取得する
     * @returns {boolean} 終了している場合はtrue
     */
    isFinishedSubscriptionOfImageSeparation() {
        // 画像分割結果のSubscriptionが終了している場合、totalが設定されている
        const progressBar = $('#bookshelf-assessment-progress-bar');
        const total = parseInt(progressBar.attr('data-assess-total'), 10);

        return total !== 0;
    }

    /**
     * 本棚査定結果に遷移した時の画面を描画
     */
    showLoadingContent() {
        // ぐるぐる表示開始
        $("#estimate-search-result-container").addClass('bookshelf-assessment-container');
        $("#bookshelf-assessment-comment").css("display","");
        $("#keyword-note-div").css("display","none");
        //処理ID表示
        $("#bookshelf-assessment-id").text(this.uploadFileName);
        $("#bookshelf-assess-processid").css("display","");
        $("#bookshelf-assessment-hidden-id").val(this.uploadFileName);

        //画像表示
        var base64data = sessionStorage.getItem(SESSION_STORAGE_KEY_OF_UPLOAD_FILE);
        $("#hidden-img").attr('src',base64data); // 背景画像ではサイズ取得が困難のため、非表示img要素を配置する
        $("#bookshelf-assess-img").css({
            "background-image": "url(" + base64data + ")",
            "display":""
        });

        // 査定する商品が見つからない場合ボタンを非表示
        $("#estimate-search-box-wrapper").css("display","none");

        //画像の高さを取得してセット
        this.getUploadImgHeight();

        // プログレスバーの領域を表示
        this.showProgressBarArea();

        // 本棚査定結果の領域を表示
        this.showAssessmentTotal()

        // booksearch.jsに定義してあるポップアップを流用して表示
        this.showSearchingPopupByBooksearch();

        // 結果なしで処理が正常終了時フロント画面側をタイムアウトで終了させる
        this.noResultLoadTimeout();
    }

    /**
     * アップロードされた画像の高さを取得する
     */
    getUploadImgHeight() {

        var virtualImg = document.getElementById('hidden-img');

        var intervalId = setTimeout( function () {
            if ( virtualImg != null && virtualImg.complete ) {
                var originHeight = virtualImg.height;
                var originWidth = virtualImg.width;
                clearTimeout( intervalId );
            }
        $("#bookshelf-assess-img").css("width", originWidth);
        $("#bookshelf-assess-img").css("height", originHeight);
        }, 500);
    }

    /**
     * 本棚査定結果の領域を表示する
     */
    showAssessmentTotal() {
        $("#bookshelf-assessment-info-wrap").css("display","");
        $('#bookshelf-assessment-total').css('display', '');
    }

    /**
     * ぐるぐるポップアップの代わりに、エラーメッセージポップアップを表示する
     * @returns {boolean}
     */
    showSubscriptionErrorPopup() {
        this.hideProgressPopup();
        return handleError('#modal-bookshelf-assessment-error');
    }

    /**
     * ぐるぐるポップアップを非表示にする
     */
    hideProgressPopup() {
        this.hideSearchingPopupByBookSearch();
    }

    /**
     * ポップアップを表示する
     * @param popupID ポップアップElementのid属性
     */
    showPopup(popupID) {
        const element = $(popupID);
        element.popup({
            transition : 'all 0.3s'
        });
        element.find('.modal-close-btn').click(function() {
            element.popup('hide');
        });
        element.popup('show');
    }

    /**
     * エラー処理を行い、エラーポップアップを表示する
     * @param popupID ポップアップElementのid属性
     * @returns {boolean}
     */
    showErrorPopup(popupID) {
        // 同じファイルを再度選択できるよう、いったん選択したファイルはクリア
        $(ID_SELECTOR_NAMES.join()).val(null);

        this.showPopup(popupID);
        return false
    }

    /**
     * 本棚査定のバックエンドでエラーが発生した時のエラー処理を行い、エラーポップアップを表示する
     * @param error バックエンドから渡されたエラーコード
     * @returns {boolean}
     */
    showBackendErrorPopup(error) {

        switch (error) {
            case SUBSCRIPTION_ERROR_CODE_NOT_FOUND:
                this.showErrorPopup('#modal-bookshelf-assessment-searching-failed');
                break;
            case SUBSCRIPTION_ERROR_CODE_ERROR:
                this.showErrorPopup('#modal-bookshelf-assessment-error');
                break;
            default:
                this.showErrorPopup('#modal-bookshelf-assessment-error');
                break;
        }
        return false;
    }

    // プログレスバーまわり

    /**
     * プログレスバーの領域を表示する
     */
    showProgressBarArea() {
        $('#bookshelf-assessment-progress').css('display', '');
    }

    /**
     * プログレスバーのパーセント表示に使う分母を設定する
     * @param total 画像の総分割数
     */
    setTotalOfSeparations(total) {
        $('#bookshelf-assessment-progress-bar').attr('data-assess-total', total);
    }


    // クラス外のJSに依存しているメソッド群

    /**
     * amplifyのモジュールを使うための設定
     * ヘッダでの処理で実装している関数を呼び出す
     */
    amplifyConfigure() {
        amplifyConfigure();
    }

    /**
     * BladeのrenderItems()関数を使って、画面に表示する
     * @param targetItems 表示対象データ
     */
    renderItemsByBlade(targetItems) {
        renderItems(targetItems, this.element, this.pricingUrl);
    }

    /**
     * booksearch.jsに定義してある関数を実行
     * 結果なし件数をカウントアップ
     */
    drawProgressBarAtNoResultByBooksearch() {
        console.log(`結果なし件数をカウントアップします`);

        drawProgressBarAtNoResult();
    }

    /**
     * booksearch.jsに定義してあるポップアップを流用・表示
     */
    showSearchingPopupByBooksearch() {
        searcingPopup.popup('show');
    }

    /**
     * booksearch.jsに定義してあるポップアップを流用したポップアップを非表示
     */
    hideSearchingPopupByBookSearch() {
        searcingPopup.popup('hide');
    }
    /**
     * 結果が正常に終了しても画面ロード中が終わらない場合タイムアウトさせる
     */
    noResultLoadTimeout() {
        var self = this;
        var errorWindow = false;
        var intervaled = setTimeout( function () {

            var errorElement = [
                '#modal-bookshelf-assessment-image-too-big',
                '#modal-bookshelf-assessment-upload-failed',
                '#modal-bookshelf-assessment-searching-failed',
                '#modal-bookshelf-assessment-timeout',
                '#modal-bookshelf-assessment-error'
            ];
            // エラー画面が表示されているかどうか
            $.each(errorElement, function(index, val) {
                if (!$(val).is(":hidden")) {
                    errorWindow = true;
                }
            });
            // 結果件数が0でかつエラー画面が何も表示されていない場合、ロード画面をタイムアウトで終了させる
            if(self.countAssessedItems() === 0 && errorWindow == false) {
                searcingPopup.popup('hide'); // ぐるぐるを非表示
                showPopup('#modal-bookshelf-assessment-searching-failed'); // 該当の商品がない場合のエラー画面表示
            }
            clearTimeout( intervaled );

        }, NO_RESULT_LOAD_TIMEOUT);
    }
}

// モジュール外で使えるよう、グローバル変数windowに入れておく
window.BookshelfAssessmentContainer = BookshelfAssessmentContainer;
export {
    assessBookshelfWithResize
}